import React, { useState, useRef } from "react";
import "./App.css";
import IconTools from "./assets/IconTools.svg";
import IconClean from "./assets/IconClean.svg";
import IconAmenity from "./assets/IconAmenity.svg";
import IconLaundry from "./assets/IconLaundry.svg";
import IconToolsActive from "./assets/IconToolsActive.svg";
import IconCleanActive from "./assets/IconCleanActive.svg";
import IconAmenityActive from "./assets/IconAmenityActive.svg";
import IconLaundryActive from "./assets/IconLaundryActive.svg";
import IconOther from "./assets/IconOther.svg";
import IconOtherActive from "./assets/IconOtherActive.svg";
import IconFood from "./assets/IconFood.svg";
import IconFoodActive from "./assets/IconFoodActive.svg";
import UploadImages from "./assets/UploadImages.svg";
import Finished from "./assets/Finished.svg";
import Camera from "./assets/Camera.svg";
import Add from "./assets/Add.svg";
import ArrowReturn from "./assets/ArrowReturn.svg";
import ArrowForward from "./assets/ArrowForward.svg";
import { animateScroll as scroll } from "react-scroll";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import Modal from "react-modal";
// import AltoLogo from "./assets/AltoLogoMobile.png";
import KokotelLogo from "./assets/KokotelLogo.png";

type ServiceButtonProps = {
  icon: string;
  iconActive: string;
  title: string;
  selectedService: string;
  onClick: (title: string, currentSection: string) => void;
};

const ServiceButton = ({ selectedService, icon, iconActive, onClick, title }: ServiceButtonProps) => {
  return (
    <div
      className={`col-span-2 h-[168px] flex flex-col rounded-2xl 
      justify-center items-center border border-altoGray cursor-pointer
      ${selectedService === title && "bg-altoBlue"}`}
      onClick={() => onClick(title, "service")}
    >
      <img src={selectedService === title ? iconActive : icon} className="w-16 h-16" alt="tools-logo" />
      <p className={`text-b1 ${selectedService === title ? "text-white" : "text-altoGray"}`}>{title}</p>
    </div>
  );
};

const ServicesList: any = [
  {
    name: "Repair",
    icon: IconTools,
    iconActive: IconToolsActive,
  },
  {
    name: "Cleaning",
    icon: IconClean,
    iconActive: IconCleanActive,
  },
  {
    name: "Amenity",
    icon: IconAmenity,
    iconActive: IconAmenityActive,
  },
  {
    name: "Laundry",
    icon: IconLaundry,
    iconActive: IconLaundryActive,
  },
  {
    name: "Food",
    icon: IconFood,
    iconActive: IconFoodActive,
  },
  {
    name: "Other",
    icon: IconOther,
    iconActive: IconOtherActive,
  },
];

const PlaceholderMap: { [serviceName: string]: string } = {
  Repair: "light is broken",
  Cleaning: "make up room at 3pm",
  Amenity: "need a new towel",
  Laundry: "2 shirts and 1 pants",
  Food: "1 Phad Thai with shrimp",
  Other: "car to airport",
};

function App() {
  const [selectedService, setSelectedService] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isOpend, setIsOpend] = useState<boolean>(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const serviceRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageRef = useRef(null);

  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList: any, addUpdateIndex: any) => {
    setImages(imageList);
  };

  const handleClickForward = (serviceName: string | undefined, currentStep: string) => {
    if (serviceName !== undefined) {
      setSelectedService(serviceName);
    }
    const windowHeight = window.innerHeight;
    if (currentStep === "service") {
      //@ts-ignore
      scroll.scrollTo(windowHeight);
    } else if (currentStep === "description") {
      //@ts-ignore
      scroll.scrollToBottom();
    }
  };

  const handleClickBackward = (serviceName: string | undefined, currentStep: string) => {
    if (serviceName !== undefined) {
      setSelectedService(serviceName);
    }
    const windowHeight = window.innerHeight;
    if (currentStep === "description") {
      //@ts-ignore
      scroll.scrollToTop();
    } else if (currentStep === "image") {
      //@ts-ignore
      scroll.scrollTo(windowHeight);
    }
  };

  const onSubmit = async () => {
    try {
      setLoading(true);
      await createTask(description);
      setLoading(false);
      setIsOpend(true);
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setIsOpend(false);
    scroll.scrollToTop();
    setSelectedService("");
    setDescription("");
    setImages([]);
  };

  const login = () => {
    setIsLogin(true);
  };

  return (
    <div className="h-screen">
      {!isLogin && <LoginPage login={login} />}
      <section className="px-4 py-4 h-screen pt-10" id="service" ref={serviceRef}>
        <div className="grid grid-cols-4">
          <h2 className="col-span-4 text-h1 font-[700] text-altoBlue text-left">Good Evening</h2>
          <h2 className="col-span-4 text-h1 text-altoBlue text-left">Mr.Joe</h2>
          <h2 className="col-span-4 text-b1 text-altoGray text-left">Room no. 201</h2>
        </div>
        <div className="grid grid-cols-4 mt-6 gap-x-4">
          <h3 className="col-span-4 text-h3 font-[700] text-altoBlack text-left">How can I help you today?</h3>
          <p className="col-span-4 text-caption font-[400] text-altoGray w-full text-left">Please select the service below.</p>
        </div>
        <div className="grid grid-cols-4 mt-6 gap-x-4 gap-y-4">
          {ServicesList.map((service: any) => (
            <ServiceButton
              selectedService={selectedService}
              icon={service.icon}
              iconActive={service.iconActive}
              onClick={handleClickForward}
              title={service.name}
            />
          ))}
        </div>
      </section>
      {selectedService && (
        <section className="px-4 py-4 h-screen" id="description" ref={descriptionRef}>
          <div className="grid grid-cols-4 mt-6 gap-x-4">
            <h3 className="col-span-4 text-h3 font-[700] text-altoBlack text-left">Tell us more about your request!</h3>
            <p className="col-span-4 text-caption font-[400] text-altoGray w-full text-left">Please enter your request detail.</p>
          </div>
          <div className="grid grid-cols-4 mt-6 gap-x-4">
            <textarea
              className="col-span-4 h-[fit-content] border-b-altoBlue border p-1 px-2"
              placeholder={`For example, ${PlaceholderMap[selectedService]}`}
              onChange={(e: any) => setDescription(e.target.value)}
            />
          </div>
          <div className="flex w-full justify-between mt-14">
            <button
              className="w-[128px] flex justify-center items-center h-[42px] text-b1 text-altoBlue rounded-lg font-[700] gap-x-1"
              onClick={() => handleClickBackward(undefined, "description")}
            >
              <img src={ArrowReturn} className="h-6 mr-1 w-6" alt="arrow-return" />
              <p>Back</p>
            </button>
            {selectedService === "Food" ? (
              <button
                className={`w-[128px] flex justify-center items-center h-[42px] 
                ${loading ? "bg-altoGray" : "bg-altoBlue"} text-b1 text-white rounded-lg font-[700] gap-x-1`}
                onClick={() => onSubmit()}
                disabled={loading}
              >
                <p>Submit</p>
                <img src={ArrowForward} className="h-6 w-6" alt="arrow-forward" />
              </button>
            ) : (
              <button
                className="w-[128px] flex justify-center items-center h-[42px] bg-altoBlue text-b1 text-white rounded-lg font-[700] gap-x-1"
                onClick={() => handleClickForward(undefined, "description")}
              >
                <p>Next</p>
                <img src={ArrowForward} className="h-6 mr-1 w-6" alt="arrow-forward" />
              </button>
            )}
          </div>
        </section>
      )}
      {selectedService && description && selectedService !== "Food" && (
        <section className="px-4 py-4 flex flex-col items-center h-screen" id="image" ref={imageRef}>
          <div className="grid grid-cols-4 mt-6 gap-x-4">
            <h3 className="col-span-4 text-h3 font-[700] text-altoBlack text-left">Would you like to attach any photo?</h3>
            <p className="col-span-4 text-caption font-[400] text-altoGray w-full text-left">Please take or add photo below.</p>
          </div>
          <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
            {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove, isDragging, dragProps }) => (
              <div className="w-[168px] flex flex-col gap-y-4 items-center mt-6">
                {imageList.length === 0 ? (
                  <>
                    <img src={UploadImages} className="w-[105px] h-[105px]" alt="upload-images" />
                    <button
                      className="w-full flex justify-center items-center h-[42px] bg-altoBlue text-b1 text-white rounded-lg font-[700] gap-y-1"
                      onClick={onImageUpload}
                      style={isDragging ? { color: "red" } : undefined}
                      {...dragProps}
                    >
                      <img src={Camera} className="h-6 mr-1 w-6" alt="camera" />
                      <p>Take a photo</p>
                    </button>
                    <button
                      className="w-full flex justify-center items-center h-[42px] bg-altoBlue bg-opacity-20 text-b1 text-altoBlue rounded-lg font-[700] gap-y-1 border border-altoBlue"
                      onClick={onImageUpload}
                    >
                      <img src={Add} className="h-6 mr-1" alt="add" />
                      <p>Add photo</p>
                    </button>
                  </>
                ) : (
                  imageList.map((image, index) => (
                    <>
                      <img src={image["data_url"]} width="200" alt="uploaded-images" />
                      <button
                        className="w-full flex justify-center items-center h-[42px] bg-altoBlue text-b1 text-white rounded-lg font-[700] gap-y-1"
                        onClick={() => onImageUpdate(index)}
                      >
                        <p>Update</p>
                      </button>
                      <button
                        className="w-full flex justify-center items-center h-[42px] bg-altoRed bg-opacity-60 text-b1 text-white rounded-lg font-[700] gap-y-1 border border-altoRed"
                        onClick={() => onImageRemove(index)}
                      >
                        <p>Remove</p>
                      </button>
                    </>
                  ))
                )}
              </div>
            )}
          </ImageUploading>
          <div className="flex w-full justify-between mt-14">
            <button
              className="w-[128px] flex justify-center items-center h-[42px] text-b1 text-altoBlue rounded-lg font-[700] gap-x-1"
              onClick={() => handleClickBackward(undefined, "image")}
            >
              <img src={ArrowReturn} className="h-6 w-6" alt="arrow-return" />
              <p>Back</p>
            </button>
            <button
              className={`w-[128px] flex justify-center items-center h-[42px] 
              ${loading ? "bg-altoGray" : "bg-altoBlue"} text-b1 text-white rounded-lg font-[700] gap-x-1`}
              onClick={() => onSubmit()}
              disabled={loading}
            >
              <p>Submit</p>
              <img src={ArrowForward} className="h-6 w-6" alt="arrow-forward" />
            </button>
          </div>
        </section>
      )}
      <SuccessModal modalIsOpen={isOpend} closeModal={closeModal} />
    </div>
  );
}

export default App;

const createTask = async (description: string) => {
  const response = await axios.post(`https://experiencebackend.altotech.net/api/v2.0/login`, {
    username: "altohoteldemo",
    password: "altohoteldemo",
  });
  const token = response.data.token;
  const headers = { Authorization: `Token ${token}` };
  const payload: any = {
    description: description,
    location: "201",
    reportType: "maid_request",
    reportUrgent: "ด่วนมาก",
    status: "new",
  };

  return axios.post(`https://experiencebackend.altotech.net/api/v2.0/maid_tasks`, { ...payload }, { headers: headers });
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "16px",
    width: "85vw",
    height: "80vh",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const SuccessModal = ({ modalIsOpen, closeModal }: any) => {
  return (
    <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
      <div className="flex flex-col items-center px-8 pt-[100px]">
        <img src={Finished} className="w-[200px] h-[200px]" alt="Finished-images" />
        <h2 className="col-span-4 text-h1 font-[700] text-altoBlack text-center">Thank you</h2>
        <h2 className="col-span-4 text-h1 font-[700] text-altoBlack text-center">for your request</h2>
        <p className="col-span-4 text-caption font-[400] text-altoGray w-full text-center mt-10">We will deliver your request shortly.</p>
      </div>
    </Modal>
  );
};

const LoginPage = ({ login }: any) => {
  const [loading] = useState(false);
  const [password, setPassword] = useState("");
  // const onSubmit = async () => {
  //   setLoading(true);
  // };

  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen px-10">
        <h1 className="text-4xl font-bold mb-5">Login</h1>
        <img className="w-32 mb-10" src={KokotelLogo} alt="logo"/>
        <p className="w-full text-left text-xl">Room 201</p>
        <input
          type="password"
          className="bt-4 w-full border border-altoWhite p-2 mb-4 mt-2"
          placeholder="Enter password"
          value={password}
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <button
          className={`w-full flex justify-center items-center h-[42px] 
              ${loading ? "bg-altoGray" : "bg-altoBlue"} text-b1 text-white rounded-lg font-[700] gap-x-1`}
          onClick={() => login()}
          disabled={loading}
        >
          <p>Login</p>
          <img src={ArrowForward} className="h-6 w-6" alt="arrow-forward" />
        </button>
      </div>
    </>
  );
};
